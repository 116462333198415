import PageNotFoundImg from "../../../assets/illustrations/404-illustration.png";
import Button from "../../ui-kit/Button";
import { Link } from "react-router-dom";

export default function PageNotFound() {
  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100 px-4">
      <div className="mt-4">
        <img
          src={PageNotFoundImg}
          alt="Error Illustration"
          className="object-contain w-full h-full"
        />
      </div>
      <h1 className="mt-6 text-[36px] md:text-[52px] font-medium text-deepBlack">
        Page Not Found
      </h1>
      <p className="mt-4 text-center text-gray-600 max-w-lg">
        Oops! The page you're looking for doesn't exist. It might have been
        moved or deleted, or perhaps the URL is incorrect.
      </p>
      <Link to="/" className="mt-8">
        <Button text="Back To Home" />
      </Link>
    </div>
  );
}
