import React from "react";

export default function Button({
  text,
  icon,
  transparent,
  handleClick,
  rightIcon,
  noBorder,
}) {
  return (
    <div>
      <button
        className={`
          ${
            transparent
              ? "bg-white text-deepBlack"
              : "bg-darkBlueGray text-white"
          }
          ${noBorder ? "" : "border border-darkBlueGray"}
          text-nowrap text-sm py-3 px-6 rounded-lg transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300`}
        onClick={handleClick}>
        {icon && !rightIcon ? (
          <div className="inline">
            <img src={icon} alt="icon" className="inline mr-2" loading="lazy" />
          </div>
        ) : null}
        <span className="align-bottom">
          {text}
          {icon && rightIcon ? (
            <div className="inline">
              <img
                src={icon}
                alt="icon"
                className="inline ml-2"
                loading="lazy"
              />
            </div>
          ) : null}{" "}
        </span>
      </button>
    </div>
  );
}
