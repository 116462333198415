import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import LoaderSpinner from "./components/ui-kit/LoaderSpinner";
import PageNotFound from "./components/pages/page-not-found/PageNotFound";

// Lazy-loaded components
const LazyLandingPage = lazy(() =>
  import("./components/pages/landing-page/LandingPage")
);
const LazyHowItWorksPage = lazy(() =>
  import("./components/pages/unlock-insights/UnlockInsightsPage")
);
const LazyElevateYourDataPage = lazy(() =>
  import("./components/pages/elevate-your-data/ElevateYourDataPage")
);
const LazyPricingPage = lazy(() =>
  import("./components/pages/pricing/PricingPage")
);
const LazyMarketingPage = lazy(() =>
  import("./components/pages/marketing/MarketingPage")
);
const LazySaasVendorsPage = lazy(() =>
  import("./components/pages/saas-vendors/SaasVendorsPage")
);
const LazyTermsAndConditionsPage = lazy(() =>
  import("./components/pages/terms-and-conditions/TermsAndConditionsPage")
);
const LazyPrivacyPolicyPage = lazy(() =>
  import("./components/pages/privacy-policy/PrivacyPolicyPage")
);

export default function App() {
  return (
    <Router>
      <Suspense fallback={<LoaderSpinner />}>
        <Routes>
          <Route path="/" element={<LazyLandingPage />} />
          <Route path="/unlock-insights" element={<LazyHowItWorksPage />} />
          <Route
            path="/elevate-your-data"
            element={<LazyElevateYourDataPage />}
          />
          <Route path="/pricing" element={<LazyPricingPage />} />
          <Route path="/marketing" element={<LazyMarketingPage />} />
          <Route path="/saas-vendors" element={<LazySaasVendorsPage />} />
          <Route
            path="/terms-and-conditions"
            element={<LazyTermsAndConditionsPage />}
          />
          <Route path="/privacy-policy" element={<LazyPrivacyPolicyPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
}
